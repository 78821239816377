import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import { useMediaQuery } from 'react-responsive'
import instance from "../../components/api/httpclient";
import {navigate, useIntl, Link} from "gatsby-plugin-intl";
import moment from "moment";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

class BodyClass extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes
  // }
  // static defaultProps = {
  //   name: ''
  // }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const RewardSingle = ({location}) => {
  const [reward, setReward] = useState({});
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : "";
  const intl = useIntl();

  useEffect(() => {
    if (location) {
      if (location.state) {
        instance.get("/rewards/" + location.state.id,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            setReward(response.data);
          }).catch((error) => {
          console.log('error', error);
        });
      }
    }
  },[]);

  const getUserLevel = (level) => {
    if (undefined !== level && currentUser) {
      return (intl.formatMessage({ id: "generic.status_level." + level }));
    }
  };

  const getRewardLevel = (level) => {
    if (undefined !== level) {
      return (intl.formatMessage({ id: "generic.status_level." + level }));
    }
  };

  const getStatutCss1 = (level) => {
    switch(level) {
      case "1":
        return 'status-bronze mb-4';
      case "2":
        return 'status-silver mb-4';
      case "3":
        return 'status-gold mb-4';
      case "4":
        return 'status-platinum mb-4';
    }
  };

  const getLevelBgClass = (level) => {
    switch(level) {
      case 1:
        return 'tag bg-bronze';
      case 2:
        return 'tag bg-silver';
      case 3:
        return 'tag bg-gold';
      case 4:
        return 'tag bg-platinum';
    }
  };

  const getStatutCss2 = (level) => {
    switch(level) {
      case "1":
        return 'icon-win bronze icon-win-text icon-win-no-bg';
      case "2":
        return 'icon-win silver icon-win-text icon-win-no-bg';
      case "3":
        return 'icon-win gold icon-win-text icon-win-no-bg';
      case "4":
        return 'icon-win platinum icon-win-text icon-win-no-bg';
    }
  };

  const closeToolTip = (e) => {
    let eventDiv = e.target.closest('.tooltip-custom');
    if (eventDiv) {
      if (eventDiv.classList.contains('d-none')) {
        eventDiv.classList.remove('d-none');
      } else {
        eventDiv.classList.add('d-none');
      }
    }
  }

  const getVoucher = (event) => {
    if (location) {
      if (location.state) {
        instance.get("/rewards/" + location.state.id + "/voucher",
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            navigate('/rewards/coupon-ready', { state: { voucherId: response.data.voucherId } })
          }).catch((error) => {
          console.log('error', error);

        });
      }
    }

    event.preventDefault();
  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">
          <Link to="/rewards/reward-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "reward_shop.coupon.back" })}</Link>
        <div className="white-container">
          <div className="reward-single__img-wrapper mb-4" style={{backgroundImage: "url(" + reward.imgHeader + ")"}}>
          </div>
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{reward.label}</h1>
          <div className="text mb-2"><strong>Description</strong></div>
          <p className="mb-4" dangerouslySetInnerHTML={{__html: reward.description}}></p>
          <p className="mb-4"><strong>{reward.voucher}</strong></p>

          <h3 className="text-uppercase"><span className="dark-red icon-ticket mr-2 "></span>{intl.formatMessage({ id: "reward_shop.offer.title" })} {reward.offer}</h3>


          <div className="text mb-4"><strong>{intl.formatMessage({id: "reward_shop.offer.date_due"})} {moment(reward.validityDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong></div>

          <div className="allowed-prize mb-4">
            {currentUser.status >= reward.availableStatus &&
            <p className="mb-2">{intl.formatMessage({id: "reward_shop.offer.access_label"})}</p>
            }
            {currentUser.status < reward.availableStatus &&
            <p className="mb-2">{intl.formatMessage({id: "reward_shop.availability_box.only_for_status"})}</p>
            }

            <div className={getStatutCss1(reward.availableStatus)}>
              <span className={getStatutCss2(reward.availableStatus)}>
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="text">{getRewardLevel(reward.availableStatus)}</span>
              </span>
            </div>

            {currentUser.status >= reward.availableStatus &&
            <Link to="#" onClick={(event => getVoucher(event))} className="button red with-icon">
              <span className="icon-download icon"></span>
              <span>{intl.formatMessage({id: "reward_shop.offer.download_now"})}</span></Link>
            }
          </div>


          <div className="tooltip-custom availability-tooltip dark-red dark-red-border text-center mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0">
            <a href="#" className="close"  onClick={(e) => closeToolTip(e)}><span>+</span></a>
            <h3 className="text dark-red mb-2 p-0 text-left"><span className="icon-questions align-middle fs-20 mr-2"></span><strong>{intl.formatMessage({ id: "reward_shop.availability_box.title" })}</strong></h3>
            <p className="dark-red text-left mb-4 ">{intl.formatMessage({ id: "reward_shop.availability_box.current_advantage.1" })}<span className={getLevelBgClass(currentUser.status)}>{getUserLevel(currentUser.status)}</span>{intl.formatMessage({ id: "reward_shop.availability_box.current_advantage.2" })}<span className={getLevelBgClass(currentUser.status)}>{getUserLevel(currentUser.status)}</span>{intl.formatMessage({ id: "reward_shop.availability_box.current_advantage.3" })}</p>
            <Link to="/rewards/about-youplus" className="button red"><span>{intl.formatMessage({ id: "reward_shop.availability_box.improve_status" })}</span></Link>
          </div>

        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.reward-single" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default RewardSingle
